import React, { useState } from 'react';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/button/Button';
import KnowCheck from './KnowCheck';
import Upload from './Upload';
import SelectInput from './SelectInput';

const CheckInForm = (props) => {
  const [driver, setDriver] = useState();
  const [truck, setTruck] = useState();
  const [equNo, setEquNo] = useState();
  const [equType, setEquType] = useState();
  const [customer, setCustomer] = useState();
  const [from, setFrom] = useState();
  const [owner, setOwner] = useState();
  const [manifest, setManifest] = useState();
  const [po, setPo] = useState();
  const [email, setEmail] = useState('maddychoudhary0786@gmail.com');
  const [mobile, setMobile] = useState('+91 9024283411');
  return (
    <div className="equipment_check_in" ref={props.maddy}>
      <div className="check_form">
        <h3 className="check_Header">EQUIPMENT DROP-OFF</h3>
        <form className="check_in_form">
          <InputField
            idName="driver_id"
            className="driver_cls"
            label="driver"
            type="text"
            name="driver"
            value={driver}
            change={setDriver}
          />
          <InputField
            idName="trucking_id"
            className="trucking_cls"
            label="trucking"
            type="text"
            name="trucking"
            value={truck}
            change={setTruck}
          />
          <InputField
            idName="equ_no_id"
            className="equ_no_cls"
            label="equipment"
            type="text"
            name="equ_no"
            value={equNo}
            change={setEquNo}
          />
          <InputField
            idName="equ_type_id"
            className="equ_type_cls"
            label="equipment type"
            type="text"
            name="equ_type"
            value={equType}
            change={setEquType}
          />
          <InputField
            idName="owner_id"
            className="owner_cls"
            label="owner"
            type="text"
            name="owner"
            value={owner}
            change={setOwner}
          />
          <SelectInput className="location_yard" label="location" />
          <InputField
            idName="customer_id"
            className="customer_cls"
            label="customer"
            type="text"
            name="customer"
            value={customer}
            change={setCustomer}
          />
          <InputField
            idName="from_id"
            className="from_cls"
            label="from"
            type="text"
            name="from"
            value={from}
            change={setFrom}
          />
          <div id="check_last_known">
            <KnowCheck />
          </div>
          <InputField
            idName="manifest_id"
            className="manifest_cls"
            label="manifest #"
            type="text"
            name="manifest"
            value={manifest}
            change={setManifest}
          />
          <div id="check_upload">
            <div className="upload">
              <div>SDS</div>
              {/* <Upload /> */}
            </div>
            <div className="add_more_equi">
              <p>add more equipment</p>
            </div>
          </div>
          <InputField
            idName="po_id"
            className="po_cls"
            label="po #"
            type="text"
            name="po"
            value={po}
            change={setPo}
          />
          <div className="text_area">
            <div>remarks</div>
            <textarea />
          </div>
          <InputField
            idName="email_id"
            className="email_cls"
            label="provide drop off confirmation"
            type="email"
            name="email"
            value={email}
            change={setEmail}
          />or
          <InputField
            idName="mobile_id"
            className="mobile_cls"
            label=""
            type="text"
            name="mobile"
            value={mobile}
            change={setMobile}
          />
          <div id="blank" />
          <div id="close_save">
            <button
              className="check_cancel"
              onClick={props.click}
              type="button"
            >
              cancel
            </button>
            <button className="check_save" type="submit">
              save
            </button>
            <button className="check_wash_ticket" type="submit">
              save & create wash ticket
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CheckInForm;

import React from 'react'

const InputField = (props) => {
    return (
        <div id={props.idName}>
            <label htmlFor={props.label}>{props.label}</label>
            <input className={props.className}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => {
                    props.change(e.target.value)
                }} />
        </div>
    )
}
export default InputField;